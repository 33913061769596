// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-featured-club-street-jsx": () => import("./../../../src/pages/featured/club-street.jsx" /* webpackChunkName: "component---src-pages-featured-club-street-jsx" */),
  "component---src-pages-greenpolicy-jsx": () => import("./../../../src/pages/greenpolicy.jsx" /* webpackChunkName: "component---src-pages-greenpolicy-jsx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-jsx": () => import("./../../../src/pages/privacy.jsx" /* webpackChunkName: "component---src-pages-privacy-jsx" */),
  "component---src-pages-services-jsx": () => import("./../../../src/pages/services.jsx" /* webpackChunkName: "component---src-pages-services-jsx" */),
  "component---src-templates-featured-jsx": () => import("./../../../src/templates/featured.jsx" /* webpackChunkName: "component---src-templates-featured-jsx" */)
}

